import { STRUCTURE_ACCUMULATOR_MINI, STRUCTURE_ACCUMULATOR } from 'constants.js';
import { isGreeksDisplaySelector } from 'pages/price/mini/components/pricinigMiniForm/accumulatorMiniLimitationSelector';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { compose } from 'redux';
import REFERENCE_FIGURES_CONTENT_CONFIG_ACCUMULATOR from './config/accumulator';
import REFERENCE_FIGURES_CONTENT_CONFIG_ACCUMULATOR_MINI from './config/accumulator-mini';

const caseConfig = (type, data) => {
  switch (type) {
    case STRUCTURE_ACCUMULATOR_MINI.toLowerCase():
      return REFERENCE_FIGURES_CONTENT_CONFIG_ACCUMULATOR_MINI(data);
    default:
      return REFERENCE_FIGURES_CONTENT_CONFIG_ACCUMULATOR;
  }
};

const ReferenceFiguresContent = props => {
  const { data, type, greeksDisplay } = props;
  const { setPremiumAction, setNotionalAction, premium, isWithoutJSON } = data;
  const config = caseConfig(type, {...data, greeksDisplay, isWithoutJSON });

  useEffect(() => {
    return () => {
      setPremiumAction(premium);
      setNotionalAction(null);
    }
  }, [])
  return (
    <dl className="dl-horizontal">
      {config
        .map(({ label, view, filter }, index) =>
          !filter || (typeof filter === 'function' && filter(data))
            ? (
              <Row key={index}>
                <Col xs={5} className="text-lighter">{label.replace('{action}', data.description.type === 'Consumer' ? 'buy' : 'sell')}</Col>
                <Col xs={6} className="text-bolder">{view(data)}</Col>
              </Row>
            )
            : null
        )}
    </dl>
  )
};

ReferenceFiguresContent.defaultProps = {
  data: {},
  type: STRUCTURE_ACCUMULATOR.toLowerCase(),
};

ReferenceFiguresContent.propTypes = {
  data: PropTypes.object.isRequired,
  config: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  greeksDisplay: isGreeksDisplaySelector(state),
});

export default compose(
  connect(mapStateToProps, {}),
)(ReferenceFiguresContent);
