import InlineInput from 'components/form/InlineInput';
import React from 'react';
import { Field } from 'redux-form';

const PricingMiniFormField = props => {
  const { label = '', placeHolder: placeHolderProp = '', isLock, isResult, isLoading, form, disabled, formulas, name, ...restField } = props;
  const placeHolder = isLock && disabled ? `Solve ${label}` : placeHolderProp;
  return (
      <div className={`control-wrapper${isResult ? ' result' : ''}`}>
        <Field
          name={name}
          {...restField}
          placeHolder={placeHolder}
          showAnimation={isResult && disabled}
          disabled={disabled || isLoading}
          form={form}
          label={label}
          component={InlineInput}
        />
      </div>
  )
}

export default PricingMiniFormField;
