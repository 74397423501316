import gql from 'graphql-tag';

export const GET_STRUCTURED_UNDERLYING = gql`query getUnderlyingQuery (
  $bloombergTicker: String!
  $issuer: String
  ) {
    getUnderlying  (
    bloombergTicker: $bloombergTicker
    issuer: $issuer
  ) {
    id
    #commodityContract
    #underlyingAsset
    title: bloombergTicker
    #volatilityBucket
    #axeBuy
    #axeSell
    #createdAt
  }
}`;

export const GET_UNDERLYINGS_FOR_CONFIGURABLE_SP = gql`
  query getUnderlyingsForConfigurableSPQuery(
    $searchTerm: String!
    ) {
    getUnderlyingsForConfigurableSP(
      searchTerm: $searchTerm
      ) {
      underlyings
    }
  }
`;
