import gql from 'graphql-tag';

export const PRINT_PRICING_MINI_FACT_SHEET_QUERY = gql`query factSheetPricingMini(
  $id: ID!
  $notional: Float!
) {
  factSheetPricingMini (
    id: $id
    notional: $notional
  ) {
    referencePrice
    reference
    currency
    underlyingReference
    tradeDate
    expiryDate
    settlementDate
    barrierShift
    value
    impliedVolatilityShift
    primaryFixingValue
    delta
    deltaPercentage
    vega
    view {
      description
    }
    userInputs {
      label
      value
      field
    }
    solvedValues {
      value
      field
    }
    structureCode
    lotSize
    businessDays
    futTradingUnits
    futValPt
    tradedCurrency
    roundingRule
    accumulatorUnderlyings {
      id
      logo
      currency
      commodityContract
      quoteUnits
    }
    description {
      id
      type
      leverage
      leverageStyle
      productDescription
      product
      remainderFeature
      barrierType
    }
    card {
      title
    }
    accumulatorUnderlyingsData {
      contractCode
    }
    factSheetData
  }
}`;

export const PRINT_PRICING_MINI_FACT_SHEET_NO_JSON_QUERY = gql`query factSheetPricingMiniNoJSON(
  $pricingTemplateId: Int
  $priceReference: Float,
  $pricingCardId: Int,
  $msaFixings: Int
  $commodityContractId: Int
  $notional: Float
) {
  factSheetPricingMiniNoJSON (
    pricingTemplateId: $pricingTemplateId
    priceReference: $priceReference
    pricingCardId: $pricingCardId
    msaFixings: $msaFixings
    commodityContractId: $commodityContractId
    notional: $notional
  ) {
    dailyLot,
    businessDays
    notionalLot
    dailyQuantity
    agileNotional
    barrierShift
    currency
    expiryDate
    futTradingUnits
    futValPt
    lotSize
    reference
    referencePrice
    roundingRule
    tradeDate
    tradedCurrency
    underlyingReference
    description {
      id
      type
      leverage
      leverageStyle
      productDescription
      product
      barrierType
    }
    accumulatorUnderlyingData {
      id
      logo
      currency
      commodityContract
      quoteUnits
      contractCode
    }
  }
}`;
