import { handleDateValue } from "components/configurableSp/utils";
import { BOOKING_FORM_CALENDAR } from "pages/booking/constants";
import { PRICING_STRUCTURED_FORM_SHEDULE } from "pages/structured-products/components/forms/constants";
import { AnyAction } from 'redux';
import { reducer as formReducer, FormStateMap } from "redux-form";
import { BOOKING_CALENDAR_FILL_ALL } from "redux/actions/booking";
import {
  STRUCTURED_PRODUCTS_FORM_UPDATE_BASE_ON_SCHEDULE,
  STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE,
  STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS,
  STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_VALUES,
} from "redux/actions/structured-products";

export default (formState: FormStateMap, formAction: AnyAction) => {
  return formReducer.plugin({
    [formAction.payload?.formName]: (state: FormStateMap, action: AnyAction) => {
      switch (action.type) {
        case STRUCTURED_PRODUCTS_SET_CUSTOM_OPTIONS_VALUES:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload.values,
            },
          };
        case STRUCTURED_PRODUCTS_FORM_VALUES_RESTORE:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload.values,
            },
          };

        case STRUCTURED_PRODUCTS_FORM_UPDATE_BASE_ON_SCHEDULE:
          const updatedValues = {};
          const { layout, formValues, schedule } = action.payload;
          layout?.forEach(({ defaultValue, id }) => {
            if (defaultValue?.includes("schedule")) {
              const value = handleDateValue(defaultValue, formValues, schedule);
              if (value) {
                updatedValues[id] = value;
              }
            }
          });
          return {
            ...state,
            values: {
              ...state.values,
              ...updatedValues,
            },
          };
        default:
          return state;
      }
    },
    [PRICING_STRUCTURED_FORM_SHEDULE]: (state: FormStateMap, action: AnyAction) => {
      switch (action.type) {
        case STRUCTURED_PRODUCTS_SCHEDULE_OPTIONS_UPDATE_ALL_FIELDS:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload,
            },
          };
        default:
          return state;
      }
    },
    [BOOKING_FORM_CALENDAR]: (state: FormStateMap, action: AnyAction) => {
      switch (action.type) {
        case BOOKING_CALENDAR_FILL_ALL:
          return {
            ...state,
            values: {
              ...state.values,
              ...action.payload,
            },
          };
        default:
          return state;
      }
    },
  })(formState, formAction);
};
