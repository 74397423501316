import gql from 'graphql-tag';
import apolloClient from 'apollo.js';
import store from 'store.js';
import { mapNodes } from 'utils/';

import { STRUCTURE_SWAP, DEFAULT_LIMIT_FOR_LOAD_CARDS } from 'constants.js';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { pricingResultsLoader } from 'redux/actions/price';

const PRICINIG_HISTORY = gql`query pricingMinifuturesHistoryQuery (
    $cursor: String
    $limit: Int
  ) {
  pricingMinifutureHistoryConnection (
    after: $cursor
    first: $limit
  ) {
    edges {
      node{
        id
        direction
        contractCode
        commodityContract
        commodityCode
        price
        contractExpiry
        quantity
        clientDirectionFirst
        clientDirectionSecond
        structure
        view
        bloombergTicker
        quotedCurrency
        externalId
        strike
        priceForCalcSpread
        baseCurrency
        termCurrency
        status
        ratio
        account
        issueSize
        structureName
        qtoValue
        ccy
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}`;

export const loadMinifutureCardsData = async (cursor = null, limit) => {
  try {
    let resp = await apolloClient.query({
      query: PRICINIG_HISTORY,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        cursor,
        limit: limit || DEFAULT_LIMIT_FOR_LOAD_CARDS
      }
    })
      .then((res) => {
        const cards = mapNodes(res.data.pricingMinifutureHistoryConnection);
        const pageInfo = res.data.pricingMinifutureHistoryConnection.pageInfo;
        return { cards, pageInfo };
      });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    console.log(e);
  }
}

const PRICING_CALCULATE_PRICE_MUTATION = gql`mutation pricingMinifuturesCalculatePriceMutation(
  $structure: String!
  $commodityCode: String!
  $commodityContract: String!
  $view: ClientViewEnumType!
  $clientDirectionFirst: ClientDirectionFirstEnumType!
  $clientDirectionSecond: ClientDirectionSecondEnumType
  $quotedCurrency: String!
  $externalId: String!
  $baseCurrency: String
  $termCurrency: String
) {
  pricingMinifutureCalculatePrice (
    structure: $structure
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    clientDirectionFirst: $clientDirectionFirst
    clientDirectionSecond: $clientDirectionSecond
    view: $view
    quotedCurrency: $quotedCurrency
    externalId: $externalId
    baseCurrency: $baseCurrency
    termCurrency: $termCurrency
  ) {
    id
    price
    direction
    strike,
    externalId
    contractExpiry
    contractCode
    priceForCalcSpread
    status
    baseCurrency
    termCurrency
    account
    issueSize
    qtoValue
    ccy
    structureName
  }
}`;

export const createCardMutation = (order) => {
  return apolloClient.mutate({
    mutation: PRICING_CALCULATE_PRICE_MUTATION,
    variables: {
      ...order
    },
  })
    .then(response => response.data.pricingMinifutureCalculatePrice);
}

const PRICING_DELETE_PRICE_MUTATION = gql`mutation pricingMinifutureCalculatePriceDeleteMutation(
  $id: String!
) {
  pricingMinifutureCalculatePriceDelete (
    id: $id
  )
}`;

export const deleteCardMutation = id => {
  return apolloClient.mutate({
    mutation: PRICING_DELETE_PRICE_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

const PRICING_UPDATE_PRICE_MUTATION = gql`mutation pricingMinifuturesCalculatePriceUpdateMutation(
  $id: String!
  $quantity: Float
  $structure: String
  $contractCode: String
  $commodityCode: String
  $commodityContract: String
  $quotedCurrency: String
  $contractExpiry: String
  $clientDirectionFirst: ClientDirectionFirstEnumType!
  $clientDirectionSecond: ClientDirectionSecondEnumType
  $baseCurrency: String
  $termCurrency: String
  $externalId: String
) {
  pricingMinifutureCalculatePriceUpdate (
    id: $id,
    quantity: $quantity
    structure: $structure
    contractCode: $contractCode
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    quotedCurrency: $quotedCurrency
    contractExpiry: $contractExpiry
    clientDirectionFirst: $clientDirectionFirst
    clientDirectionSecond: $clientDirectionSecond
    baseCurrency: $baseCurrency
    termCurrency: $termCurrency
    externalId: $externalId
  ) {
    id
    price
    direction
    priceForCalcSpread
    baseCurrency
    termCurrency
    status
  }
}`;

export const updateCardMutation = card => {
  const variables = {
    ...card
  };
  return apolloClient.mutate({
    mutation: PRICING_UPDATE_PRICE_MUTATION,
    variables,
  })
    .then(response => response.data.pricingMinifutureCalculatePriceUpdate);
}

export const CREATE_ORDER_MUTATION = gql`mutation createMinifutureOrderMutation(
  $commodityCode: String!
  $contractExpiry: String!
  $quantity: Float!
  $direction: PricingSwapDirectionEnumType!
  $price: Float
  $quotedCurrency: String!
  $contractCode: String!
  $commodityContract: String!
  $structure: String!
  $timeInForce: String!
  $view: ClientViewEnumType
  $clientDirectionFirst: ClientDirectionFirstEnumType
  $clientDirectionSecond: ClientDirectionSecondEnumType
  $orderType: String!
  $stopPrice: Float
  $maxShow: Float
  $expiryDate: DateTime
  $isin: String
  $slackIceClientsId: ID
  $issueSize: Float
  $strikeLevel: Float
  $totalSold: Float
  $remaining: Float
  $fairValue: Float
  $priceForCalcSpread: Float
  $quantoValue: Float
  $ratio: Float
) {
  createOrder(
    commodityCode: $commodityCode
    contractExpiry: $contractExpiry
    quantity: $quantity
    direction: $direction
    price: $price
    quotedCurrency: $quotedCurrency
    contractCode: $contractCode
    commodityContract: $commodityContract
    structure: $structure
    timeInForce: $timeInForce
    view: $view
    clientDirectionFirst: $clientDirectionFirst
    clientDirectionSecond: $clientDirectionSecond
    orderType: $orderType
    stopPrice: $stopPrice
    maxShow: $maxShow
    expiryDate: $expiryDate
    isin: $isin
    slackIceClientsId: $slackIceClientsId
    issueSize: $issueSize
    strikeLevel: $strikeLevel
    totalSold: $totalSold
    remaining: $remaining
    fairValue: $fairValue
    priceForCalcSpread: $priceForCalcSpread
    quantoValue: $quantoValue
    ratio: $ratio
    ) {
      id
    }
}`;

export const MINIFUTURE_LOAD_FORM_DATA = gql`query loadAllDataMinifuture {
  pricingMinifutureContractsConnection {
    structureStatus
    commodities {
      underlyingId
      commodity
      commodityCode
      quotedCurrency
      baseCurrency
      termCurrency
    }
  }
}`;

export const MINIFUTURE_LOAD_PRODUCTS = gql`query minifutureProducts (
  $type: ClientDirectionSecondEnumType
  $commodityCode: String
  $clientId: String
) {
  getProducts (
    type: $type
    commodityCode: $commodityCode
    clientId: $clientId
  ) {
    value
    label
  }
}`;

export const loadMinifutureData = async () => {
  store.dispatch(pricingResultsLoader(true));
  try {
    return await apolloClient.query({
      query: MINIFUTURE_LOAD_FORM_DATA,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
    }).then((res) => {
      const data = res.data && res.data.pricingMinifutureContractsConnection;
      return Array.isArray(data.commodities) && data.commodities;
    });
  } catch (e) {
    store.dispatch(pricingResultsLoader(false));
    console.log(e);
  }
}

export const loadedMinifutureProducts = async (payload) => {
  try {
    return await apolloClient.query({
      query: MINIFUTURE_LOAD_PRODUCTS,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: payload
    })
      .then((res) => {
        const data = res.data && res.data.getProducts;
        return Array.isArray(data) && data.map(i => ({
          value: i.value,
          label: i.label,
        }));
      });
  } catch (e) {
    console.log(e);
  }
}

const MINIFUTURE_REQUEST_PRICE_MUTATION = gql`mutation minifutureRequestPriceMutation(
  $id: String!
) {
  pricingMinifutureRequestPrice (
    id: $id
  ) {
    id
    status,
  }
}`;

export const requestPriceMutation = ({
    id,
  }) => {
  return apolloClient.mutate({
    mutation: MINIFUTURE_REQUEST_PRICE_MUTATION,
    variables: {
      id,
    },
  }).then(response => response.data.pricingMinifutureRequestPrice);
}

export const CREATE_MF_FX_ORDER_MUTATION = gql`mutation createMfFxOrderMutation(
  $commodityCode: String!
  $id: String!
  $quoteId: String
  $price: Float
  $structure: String!
  $timeInForce: String!
  $view: ClientViewEnumType
  $clientDirectionFirst: ClientDirectionFirstEnumType
  $clientDirectionSecond: ClientDirectionSecondEnumType
  $orderType: String!
  $expiryDate: DateTime
  $isin: String
  $slackIceClientsId: ID
  $issueSize: Float
  $strikeLevel: Float
  $totalSold: Float
  $remaining: Float
  $fairValue: Float
  $priceForCalcSpread: Float
  $baseCurrency: String
  $termCurrency: String
  $ccy: String
  $quantoValue: Float
  $ratio: Float
) {
  createMfFxOrder(
    commodityCode: $commodityCode
    cardId: $id
    quoteId: $quoteId
    price: $price
    structure: $structure
    timeInForce: $timeInForce
    view: $view
    clientDirectionFirst: $clientDirectionFirst
    clientDirectionSecond: $clientDirectionSecond
    orderType: $orderType
    expiryDate: $expiryDate
    isin: $isin
    slackIceClientsId: $slackIceClientsId
    issueSize: $issueSize
    strikeLevel: $strikeLevel
    totalSold: $totalSold
    remaining: $remaining
    fairValue: $fairValue
    priceForCalcSpread: $priceForCalcSpread
    baseCurrency: $baseCurrency
    termCurrency: $termCurrency
    ccy: $ccy
    quantoValue: $quantoValue
    ratio: $ratio
    ) {
      id,
      status
    }
}`;
