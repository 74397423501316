import InlineSelectInput from 'components/form/inputs/InlineSelectInput';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

const PricingMiniFormContractExpiry = props => {
  const {
    isLoading = false,
    contractExpiries = [],
  } = props;

  return (
    <Field
      name="contractExpiry"
      component={InlineSelectInput}
      justValue={true}
      className={'select-disabled'}
      forceDisabled={isLoading}
      options={contractExpiries}
      label={<FormattedMessage id="form.price.contract"
        defaultMessage="Contract" />}
    />
  );
};

PricingMiniFormContractExpiry.defaultProps = {
  isLoading: false,
};

PricingMiniFormContractExpiry.propTypes = {
  contractExpiries: PropTypes.array.isRequired,
};

export default PricingMiniFormContractExpiry
