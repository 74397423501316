import React, { Fragment, useMemo } from 'react';
import PriciniMiniChartBlock from 'pages/price/mini/components/chart/PriciniMiniChartBlock';
import FactSheet from 'pages/price/mini/components/factsheet/FactSheet';
import { ProductDescriptionBtn } from 'pages/price/mini/components/pricinigMiniForm/ProductDescriptionControl';
import CopyCardControl from 'pages/price/mini/components/pricinigMiniForm/CopyCardControl';
import { isEmpty } from 'lodash';
import { PRICING_MINI_CARD_TYPE_FLEX } from './constants';

const PricingMiniControls = ({
  bloombergTicker,
  form,
  fields,
  id,
  trailId,
  isFactSheet,
  title,
  productDescription,
  subTitle,
  description,
  type,
  pricingCardId,
  selectedUnderlying,
  pricingTemplateId,
}) => {

  const premiumField = useMemo(() => fields?.filter((field) => field.name === "premiumAmount"), [fields]);

  return (
    <Fragment>
      <div className="card-mini-controls-wp">
        <div className="title-block">
          <div className="h5 sub-title">
            {subTitle}
          </div>
        </div>
        <div className="form-card-mini-content-controls">
          <ProductDescriptionBtn cardId={id} title={subTitle} productDescription={productDescription} />
          <PriciniMiniChartBlock bloombergTicker={bloombergTicker} form={form} fields={fields} cardId={id} />
          {trailId ?? type === PRICING_MINI_CARD_TYPE_FLEX ?
            <FactSheet
              cardId={id}
              trailId={trailId}
              bloombergTicker={bloombergTicker}
              commodityContract={title} 
              premiumField={!isEmpty(premiumField) && premiumField[0]}
              type={type}
              pricingCardId={pricingCardId}
              selectedUnderlying={selectedUnderlying}
              pricingTemplateId={pricingTemplateId}
              strutureName={subTitle}
            />
            : null}
          <CopyCardControl cardId={id}/>
        </div>
      </div>
      {description ? <div className="font-sm mb-3">{description}</div> : null}
    </Fragment>
)};

export default PricingMiniControls;
