import uniqueId from "lodash/uniqueId";

const createUniqueFieldName = (name: string) => uniqueId(`${name}_`);
export const UNDERLYING_PREFIX = "underlying";

export const createEmptyUnderlying = () => ({
  name: createUniqueFieldName(UNDERLYING_PREFIX),
  options: [],
});

export const createEmptyUnderlyings = (total: number) =>
  Array(total)
    .fill(undefined)
    .map(() => createEmptyUnderlying());

export interface UnderlyingField {
  name: string;
  value?: string;
  options: { value: string; label: string; iceTicker?: string[] }[];
}

export const getUnderlyingIndex = (
  name: string,
  fields: UnderlyingField[] = [],
) => {
  return Array.isArray(fields)
    ? fields.findIndex(({ name: fName }) => fName === name)
    : null;
};