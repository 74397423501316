import React, { useEffect, useState } from 'react';
import { STRUCTURE_ACCUMULATOR_MINI } from 'constants.js';
import PrintPriceContent from 'pages/print/price/PrintPriceContent';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import { roundToClosest } from 'redux/epics/price/mini/utils';
import 'pages/print/price/PrintPrice.scss';
import { PRICING_MINI_CARD_TYPE_FLEX } from '../pricinigMiniForm/constants';
import { getFactSheetData, getMarketDataId } from './utils';
import { isNil } from 'lodash';

const mapPricingMiniFields = {
  'accLevel': 'strikeLevel',
  'barrLevel': 'barrierLevel',
};

const pricingMiniData = (data = {}, { commodityContract: commodityContractProp, marketDataId, strutureName }) => {
  const {
    underlyingReference: bloombergTicker,
    solvedValues = [],
    tradedCurrency: quotedCurrency,
    futTradingUnits,
    userInputs = [],
    roundingRule,
  } = data;

  const title = data?.card?.title || strutureName;

  const accumulatorUnderlyings = data?.accumulatorUnderlyings || {};
  const accumulatorUnderlyingData = data?.accumulatorUnderlyingData ?? {};
  const description = data?.description || {};

  const commodityContract = accumulatorUnderlyings?.commodityContract || accumulatorUnderlyingData?.commodityContract || commodityContractProp;
  const quoteUnits = accumulatorUnderlyings?.quoteUnits || accumulatorUnderlyingData?.quoteUnits;
  const { remainderFeature = 'none', barrierType } = description;

  let result = {
    ...data,
    quotedCurrency,
    futTradingUnits,
    quoteUnits,
    remainderFeature,
    bloombergTicker,
    commodityContract,
    barrierType,
    accumulatorUnderlyings,
    description: {
      ...description,
      product: title
    },
    marketData: { id: marketDataId },
  };

  const solveFields = [];
  if (Array.isArray(solvedValues)) {
    solvedValues.forEach(({ field, value }) => {
      const name = mapPricingMiniFields[field] || field;
      const roundedValue = roundToClosest(value, roundingRule);
      result[name] = Number(roundedValue);
      if (name === 'strikeLevel') result['accumulationLevel'] = Number(roundedValue);
      solveFields.push(field);
    });
  }

  if (Array.isArray(userInputs)) {
    userInputs
      .filter(({ field }) => !solveFields.includes(field))
      .forEach(({ field, value }) => {
        const name = mapPricingMiniFields[field] || field;
        if (name === 'strikeLevel') result['accumulationLevel'] = value;
        result[name] = value;
      });
  }

  return result;
}

const FactSheetPrint = props => {
  const { 
    onClose,
    bloombergTicker,
    client,
    commodityContract,
    premium,
    premiumField,
    setPremiumAction,
    setNotionalAction,
    type,
    pricingTemplateId,
    trailId,
    notional,
    pricingCardId,
    msaFixings,
    selectedUnderlyingId,
    reference: priceReference,
    strutureName
  } = props;

  const [isLoading, setLoader] = useState(true);
  const [marketDataId, setMarketDataId] = useState(null);
  const [factSheetData, setFactSheetData] = useState(null);
  const [data, setData] = useState(null);

  const loadData = async () => {
    if (bloombergTicker) {
      const { data: { getMarketDataByBloombergTicker } = {} } = await getMarketDataId({client, bloombergTicker});
      if (getMarketDataByBloombergTicker?.id) setMarketDataId(getMarketDataByBloombergTicker.id);
    }
    setLoader(false)
  };

  const loadFactSheet = async () => {
    setLoader(true)
    const data = await getFactSheetData({
      client, 
      trailId, 
      notional, 
      type, 
      pricingCardId, 
      msaFixings, 
      selectedUnderlyingId, 
      pricingTemplateId, 
      priceReference,
    });

    if (type === PRICING_MINI_CARD_TYPE_FLEX) {
      const { data: { factSheetPricingMiniNoJSON } = {} } = data ?? {};
      setFactSheetData(factSheetPricingMiniNoJSON);
    } else {
      const { data: { factSheetPricingMini } = {} } = data ?? {};
      setFactSheetData(factSheetPricingMini);
    }

    setLoader(false)
  }

  useEffect(() => {
    if (!isNil(factSheetData)) {
      const pricingMiniDataObj = pricingMiniData(factSheetData, { commodityContract, marketDataId, strutureName })
      setData(pricingMiniDataObj);
    }
  }, [factSheetData])

  useEffect(() => {
    loadData();
    loadFactSheet();
  }, []);

  return !isLoading && !isNil(data) && <PrintPriceContent 
          onClose={onClose} 
          {...data} 
          loading={isLoading} 
          type={STRUCTURE_ACCUMULATOR_MINI.toLowerCase()} 
          premium={premium} 
          premiumField={premiumField} 
          setPremiumAction={setPremiumAction} 
          setNotionalAction={setNotionalAction}
          isWithoutJSON={type === PRICING_MINI_CARD_TYPE_FLEX ? true : false}
         />
};

export default compose(
  withApollo,
)(FactSheetPrint);
