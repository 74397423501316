import { INPUT_NUMBER_MAX_VALUE_LARGE } from 'constants.js';
import validateJs from 'utils/validate.js';
import validateMessages from 'utils/validate.messages';

interface PremiumField {
  premiumField?: {
    formulas: {
      min: string;
      max: string;
    }
  }
}

export default function (data = {}, props: validateJs.ValidateOption & PremiumField = {}) {
  const { premiumField: { formulas: { min, max } = {}} = {} } = props;
  const validations = {
    notional: {
      presence: {
        message: `^Notional ${validateMessages.blank}`
      },
      numericality: {
        greaterThan: 0,
        lessThanOrEqualTo: INPUT_NUMBER_MAX_VALUE_LARGE,
      }
    },
    premiumAmount: {
      presence: {
        message: `^Premium ${validateMessages.blank}`
      },
      numericality: {
        greaterThanOrEqualTo: min && parseInt(min),
        lessThanOrEqualTo: max && parseInt(max),
      }
    },
  };

  return validateJs(data, validations, props) || {};
};
