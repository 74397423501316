import { GRAPHQL_CACHE_FIRST, GRAPHQL_NETWORK_ONLY } from "components/graphql/constants";
import { PRICING_MINI_CARD_TYPE_FLEX } from "../pricinigMiniForm/constants";
import { PRINT_PRICING_MINI_FACT_SHEET_NO_JSON_QUERY, PRINT_PRICING_MINI_FACT_SHEET_QUERY } from "./query";
import { GET_MARKET_DATA_BY_BLOOMBERG } from "pages/price/ProductChart/accumulator/query";
import { DocumentNode } from "graphql";

interface GetFactSheetDataProps {
    client: any;
    trailId: string;
    notional: number;
    type: string;
    pricingCardId: number;
    msaFixings: number | null;
    selectedUnderlyingId: number;
    pricingTemplateId: number;
    priceReference: number;
}

export const getFactSheetData = async ({
    client, 
    trailId, 
    notional, 
    type, 
    pricingCardId, 
    msaFixings = null, 
    selectedUnderlyingId, 
    pricingTemplateId, 
    priceReference 
}: GetFactSheetDataProps) => {
    let variables = {};
    let query: DocumentNode | null = null;

    if (type === PRICING_MINI_CARD_TYPE_FLEX) {
        variables = {
            pricingTemplateId,
            pricingCardId,
            commodityContractId: selectedUnderlyingId,
            priceReference,
            msaFixings,
            notional
        };

        query = PRINT_PRICING_MINI_FACT_SHEET_NO_JSON_QUERY;
    } else {
        variables = {
            id: trailId,
            notional,
        };

        query = PRINT_PRICING_MINI_FACT_SHEET_QUERY;
    }

    const response = await client.query({
        query,
        variables,
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
      }).catch(e => {
        console.log(e);
      });
    
    return response;
}

export const getMarketDataId = async ({client, bloombergTicker}: {client: any, bloombergTicker: string}) => {
    const response = await client.query({
      query: GET_MARKET_DATA_BY_BLOOMBERG,
      variables: { bloombergTicker },
      fetchPolicy: GRAPHQL_CACHE_FIRST,
    }).catch(e => {
      console.log(e);
    });
    return response;
  }