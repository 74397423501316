import { INPUT_NUMBER_MAX_VALUE } from 'constants.js';
import update from 'immutability-helper';
import escape from 'lodash/escape';
import floor from 'lodash/floor';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import round from 'lodash/round';
import { create, all } from 'mathjs';
import { PRICING_MINI_FORM } from "pages/price/mini/components/pricinigMiniForm/PricingMiniForm";
import { blur } from 'redux-form';
import { PRICING_FORM } from 'redux/actions/price';
import { PREMIUM_AMOUNT_FIELD } from 'redux/epics/constants';
import { TAS_XML_TYPE } from '@agile/common-types/build/pricing-mini/types'

const mathjs = create(all);
mathjs.config({
  number: 'BigNumber', // Default type of number:
  precision: 64 // Number of significant digits for BigNumbers
});

export const roundToClosest = (number, closest = 2) => {
  const tick = closest && closest > 0 ? closest : 0.01;
  const c = mathjs.bignumber(tick);
  const rounded = mathjs.round(
    mathjs.divide(mathjs.bignumber(number), c)
  );

  // @TODO: use Number(mathjs.multiply(rounded, c));
  //!!! function retun object instead of number Example: constructor: ƒ Decimal(v)
  return mathjs.multiply(rounded, c);
};

export const pricinigMiniFormFilter = action => action && action.meta && action.meta.form.indexOf(PRICING_MINI_FORM) >= 0;

export const barrLevelRanges = (reference) => {
  const min = floor(reference * 0.75, 0);
  const max = floor(reference * 0.98, 0);
  return {
    min,
    max,
  }
};

export const accLevelRanges = (reference) => {
  const min = reference;
  const max = round(reference * 1.1, 0);
  return {
    min,
    max,
  }
};

export const inRangeValue = (value, min, max) => {
  if (value >= min && value <= max) {
    return value;
  } else if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }

  return min;
};

const getDefaultValue = (field = {}, formData = {}, roundingRule) => {
  const { defaultValue } = field;
  const formula = defaultValue || processFormula(defaultValue);
  return formula ? calculateFormula(formula, formData, roundingRule) : null;
};

export const updatePricingMiniCard = (formName, formData = {}, card, reference, setDefalultValue = false, roundingRule = null) => {
  let actions = [];
  let fieldsDisabled = [];
  const cardData = {
    ...card,
    isCardResult: false,
    trailId: null,
    tradeId: null,
  };
  if (!card?.fields) return { newCard: cardData, actions, fieldsDisabled };
  const { useMsa, xmlType } = cardData;
  const newCard = update(cardData, {
    fields: {
      $apply: (fields = []) => fields.map(
        field => {
          const formulas = field.formulas ? field.formulas : extractRangesFormula(field.range);
          const formValues = { ...formData, reference };
          const ranges = getFieldRanges(formulas, formValues, roundingRule);
          const fieldValue = setDefalultValue && field.defaultValue ? getDefaultValue(field, formValues, roundingRule) : formData[field.name];
          const value = inRangeValue(fieldValue, ranges.min, ranges.max);
          const isSolveFor = field.disabled && field.isLock;
          const isEnablePremiumForTasStructure = field.name === PREMIUM_AMOUNT_FIELD && xmlType.toLowerCase() === TAS_XML_TYPE && useMsa;
          actions.push(blur(formName, field.name, value));

          if (isSolveFor && !isEnablePremiumForTasStructure) fieldsDisabled.push(field.name);
          return ({
            ...field,
            ...ranges,
            isResult: false,
          })
        }
      )
    }
  });
  return { newCard, actions, fieldsDisabled };
};

export const emptyPricingFormFilter = state => state.value && state.value.form && !state.value.form[PRICING_FORM];

const processFormula = (formlua, replaceValTo = '') => isString(formlua) ? formlua.replace(/ /g, replaceValTo) : null;

export const extractRangesFormula = (ranges = '') => {
  const rangesArray = ranges.split(',');
  const isProcessFormula = Array.isArray(rangesArray);
  const min = isProcessFormula && rangesArray[0] ? processFormula(rangesArray[0]) : null;
  const max = isProcessFormula && rangesArray[1] ? processFormula(rangesArray[1]) : null;

  return {
    min,
    max
  };
};

export const calculateFormula = (formula, values = {}, roundingRule = null) => {
  let result = null;
  let calculateFormula = formula;
  try {
    if (!isEmpty(values)) {
      for (const key of Object.keys(values)) {
        const value = values[key] && parseFloat(values[key]) ? parseFloat(escape(values[key])) : null;
        values[key] = mathjs.bignumber(value);
      }
      const val = mathjs.evaluate(calculateFormula, values);
      const valNumber = parseFloat(val);
      result = isNumber(valNumber) ? parseFloat(roundToClosest(val, roundingRule)) : null; //@TODO: try to avoid eval
    }
  } catch (e) {
    console.log(calculateFormula, values, e);
  }
  return result;
};

export const getFieldRanges = (formulas = {}, values, roundingRule) => {
  const { min: minFormula, max: maxFormula } = formulas;
  const min = calculateFormula(minFormula, values, roundingRule) || 0;
  const max = calculateFormula(maxFormula, values, roundingRule) || INPUT_NUMBER_MAX_VALUE;
  return {
    min,
    max,
  }
};

export const getPricingMiniFormName = cardId => `${PRICING_MINI_FORM}_${cardId}`;
