import { closeWsLink, connectWsLink } from 'apollo.js';
import { getAuthToken } from 'auth/';
import Alerts from 'components/Alerts';
import Footer from 'components/common/footer/Footer';
import Loader from 'components/common/loader/Loader';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import IdleUser from 'components/IdleUser';
import { selectorAccount } from 'components/layout/logged/LoggedHeader';
import ThemeDetect from 'components/theme/ThemeDetect';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import { ROLE_PORTAL } from 'pages/roles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { login, logout, setAccountAction } from 'redux/actions/auth';
import { themeSet, themeSetInitial } from 'redux/actions/theme';
import { toursWelcome } from 'redux/actions/tours';
import { dismissNotificationUserPageContent, notificationUserPageContent } from 'redux/epics/info-messages';
import { isEnabledForEnv } from 'redux/epics/neon';
import { createSelector } from 'reselect';
import '../nonLogged/NonLoggedLayout.scss';
import LoggedHeader from './LoggedHeader';
import { ACCEPT_ADDITIONAL_TERMS_MUTATION, DISABLE_USER_QUIDE, GET_USER_ACCOUNT } from './query';
import { expirationPasswordWarning } from 'redux/alerts/actions';

const withUserRoles = (userData) => {
  // @TODO: remove hardcoded roles
  let { roles = [] } = userData;
  if (isEnabledForEnv(['demo'])) {
    roles = [
      ...roles,
      { id: userData.roles.length + 1, name: ROLE_PORTAL }
    ];
  }

  return {
    ...userData,
    roles: orderBy(roles, 'name', 'desc')
  };
}

class LoggedLayout extends Component {
  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.getCurrentUser();
  }

  componentWillUnmount() {
    this.forseLogout();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.userData, nextProps.userData) ||
      !isEqual(this.props.location, nextProps.location) ||
      !isEqual(this.props.match, nextProps.match);
  }

  disableUserGuide = async () => {
    const { client } = this.props;
    try {
      let response = await client.mutate({
        mutation: DISABLE_USER_QUIDE
      })
      return response.data;
    } catch (e) {
      console.log(e.graphQLErrors);
    }
  }

  getCurrentUser = async () => {
    const { setAccount, client, toursWelcome, login, acceptAdditionalTermsPage, expirationPasswordWarning } = this.props;
    let theme = null;
    try {
      let response = await client.query({
        query: GET_USER_ACCOUNT,
        fetchPolicy: GRAPHQL_NETWORK_ONLY
      });

      const { data } = response;
      if (data?.userData) {
        const { userData = {} } = data;
        const { theme: themeData, ...user } = userData;
        theme = themeData;
        const userDataWithRoles = withUserRoles(user);
        setAccount(userDataWithRoles);
        login();
        connectWsLink();
        if (userData.isGuide) {
          this.disableUserGuide();
          toursWelcome(true);
        }

        if(userData.passwordExpirationWarning) {
          expirationPasswordWarning(userData.passwordExpirationWarning);
        }

        if (!userData.isAcceptedAdditionalTerms) {
          acceptAdditionalTermsPage({
            seo: 'agile-terms-and-conditions',
            onClose: this.declineAdditionalTerms,
            onAccept: this.acceptAdditionalTerms
          });
        }
      }
    } catch (e) {
      this.forseLogout();
      console.log(e.graphQLErrors);
    }
    this.setUserTheme(theme);
  }

  setUserTheme = (theme) => {
    const { themeSet } = this.props;
    themeSet(theme);
  }

  forseLogout = () => {
    const { logout } = this.props;
    logout();
    closeWsLink();
  }

  acceptAdditionalTerms = () => {
    const { client, hideAcceptAdditionalTermsPage, userData, setAccount } = this.props;
    return client.mutate({
      mutation: ACCEPT_ADDITIONAL_TERMS_MUTATION
    }).then((resp) => {
      hideAcceptAdditionalTermsPage();
      setAccount({
        ...userData,
        isAcceptedAdditionalTerms: true
      })
      return resp;
    });
  }

  declineAdditionalTerms = () => {
    const { logout, hideAcceptAdditionalTermsPage } = this.props;
    logout();
    hideAcceptAdditionalTermsPage();
  }

  render() {
    const { children, userData } = this.props;
    return (
      <div className="logged-layout">
        <ThemeDetect />
        {userData ?
          <IdleUser>
            <LoggedHeader />
          </IdleUser>
          : <Loader />}
        <div className="main-content">
          <Container className="alert-container">
            <Alerts />
          </Container>
          {userData && userData.roles ? React.cloneElement(children, { roles: userData.roles }) : null}
        </div>
        <Footer />
      </div>
    );
  };
}

LoggedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  setAccount: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  themeSet: PropTypes.func.isRequired,
  toursWelcome: PropTypes.func.isRequired,
  acceptAdditionalTermsPage: PropTypes.func.isRequired,
  hideAcceptAdditionalTermsPage: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = createSelector(
  selectorAccount,
  account => ({ userData: account ? account : null })
);

const mapDispatchToProps = dispatch => bindActionCreators({
  setAccount: setAccountAction,
  login,
  logout,
  themeSet,
  themeSetInitial,
  toursWelcome,
  acceptAdditionalTermsPage: notificationUserPageContent,
  hideAcceptAdditionalTermsPage: dismissNotificationUserPageContent,
  expirationPasswordWarning,
}, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withApollo,
)(LoggedLayout);
