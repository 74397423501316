import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import FactSheetIcon from '@material-ui/icons/ListAlt';
import Modal from 'components/modal/Modal';
import { normalizeId } from 'pages/orders/utils';
import { FormattedMessage } from 'react-intl';
import { Button, UncontrolledTooltip } from 'reactstrap';
import FactSheetModalForm from './FactSheetModalForm';
import FactSheetPrint from './FactSheetPrint';
import { miniFormCardName } from '../pricinigMiniForm/PricingMiniForm';
import { pricingMiniFormValueSelector } from '../pricinigMiniForm/utils';
import { PRICING_MINI_CARD_TYPE_FLEX } from '../pricinigMiniForm/constants';

interface FactSheetProps {
  trailId: string;
  cardId: string;
  commodityContract: string;
  type: string;
  bloombergTicker?: string;
  [key: string]: any; 
}

const FactSheet = (props: FactSheetProps): ReactElement<any, any> | null => {
  const { 
    trailId,
    bloombergTicker,
    cardId,
    commodityContract,
    premiumAmount: premiumAmountUserInput,
    premiumField,
    type,
    msaFixings,
    pricingCardId,
    selectedUnderlying,
    pricingTemplateId,
    reference,
    strutureName
  } = props;

  const [isFactSheetOpen, setFactSheetOpen] = useState(false);
  const [isOpenModal, toggleModal] = useState(false);
  const [notional, setNotional] = useState(null);
  const [premiumAmount, setPremiumAmount] = useState(premiumAmountUserInput);

  const initialValues = {
    notional,
    premiumAmount: premiumAmountUserInput
  }

  const toggleFactSheet = () => setFactSheetOpen(!isFactSheetOpen);
  const setNotionalAction = (value) => setNotional(value);
  const setPremiumAction = (value) => setPremiumAmount(value);

  const togglePopup = () => toggleModal(!isOpenModal);
  const onCloseModal = () => toggleModal(false);

  return (
    isFactSheetOpen ?
      (trailId ?? type === PRICING_MINI_CARD_TYPE_FLEX ? <FactSheetPrint
        trailId={trailId}
        bloombergTicker={bloombergTicker}
        commodityContract={commodityContract}
        notional={notional}
        premium={premiumAmount}
        onClose={toggleFactSheet}
        premiumField={premiumField}
        setPremiumAction={setPremiumAction}
        setNotionalAction={setNotionalAction}
        type={type}
        pricingCardId={pricingCardId}
        msaFixings={msaFixings}
        selectedUnderlyingId={selectedUnderlying.id}
        pricingTemplateId={pricingTemplateId}
        reference={reference}
        strutureName={strutureName}
      /> : null)
      :
      <div className="factsheet-block">
        <Button id={`pricing-mini-factsheet-${normalizeId(cardId)}`} color="link" size="sm" className="btn-icon" onClick={togglePopup}>
          <FactSheetIcon />
        </Button>
        <UncontrolledTooltip placement="top" target={`pricing-mini-factsheet-${normalizeId(cardId)}`}>
          <FormattedMessage id="price.factsheet"
            defaultMessage="Factsheet" />
        </UncontrolledTooltip>
        <Modal
          btnType="primary"
          btnClass="btn-icon"
          isOpen={isOpenModal}
          onToggle={onCloseModal}
          onClose={onCloseModal}
          component={<FactSheetModalForm {...props} toggleFactSheet={toggleFactSheet} setNotionalAction={setNotionalAction} setPremiumAction={setPremiumAction} initialValues={initialValues} />}
        />
      </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { cardId } = ownProps;
  const form = miniFormCardName(cardId);

  const premiumAmount = pricingMiniFormValueSelector(form)(state, 'premiumAmount');
  const msaFixings = pricingMiniFormValueSelector(form)(state, 'msaFixings');
  const reference = pricingMiniFormValueSelector(form)(state, 'reference');


  return {
    premiumAmount,
    msaFixings,
    reference
  }
}

export default connect(mapStateToProps)(FactSheet);
