import { from, merge } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import {
  filter,
  map,
  switchMap,
  takeUntil,
  distinctUntilChanged,
  bufferTime,
  debounceTime,
} from "rxjs/operators";
import isEmpty from "lodash/isEmpty";
import { change } from 'redux-form';
import { AUTH_LOGOUT } from "redux/actions/auth";
import {
  MINIFUTURE_FORM_LOAD,
  MINIFUTURE_DATA_LOADED,
  loadedMinifutureData,
  minifutureCardsUpdate,
  MINIFUTURE_CARD_REFETCH,
} from "redux/actions/minifuture";
import { changeAnyFormFieldActionFilter } from "utils/reduxFormSelector";
import { priceFormDestroyFilter } from "../price/filters";
import { priceFormValueSelector } from "redux/epics/price/price-form";
import { loadAllVariable } from "redux/queries/price";
import { LIMITATION_RELATION_SWAP } from "redux/prices/config-swap";
import {
  loadedMinifutureProducts,
  loadMinifutureData,
} from "redux/queries/minifuture";
import { setLimitationForm, PRICING_FORM } from "redux/actions/price";
import { subscribeSwapCommodityContracts } from "../../queries/swap";
import {
  neonCommodityUpdateAction,
  NEON_COMMODITY_UPDATE,
} from "redux/actions/neon";
import { distinctObjectChanges } from "redux/epics/utils";
import { updateCardsState } from "redux/epics/neon";
import { STRUCTURE_MINIFUTURE, BUFFER_TIMEOUT } from "constants.js";
import { extractSwapContract } from "pages/price/output/selectors";


export const loadMinifutereForm = (action$) =>
  action$.pipe(
    ofType(MINIFUTURE_FORM_LOAD),
    switchMap(() => {
      return from(loadMinifutureData()).pipe(
        takeUntil(
          action$.pipe(
            filter(
              (action) =>
                action.type === AUTH_LOGOUT || priceFormDestroyFilter(action)
            )
          )
        )
      );
    }),
    switchMap((data) => {
      const actions = [loadedMinifutureData({ pricings: data })];
      return from(actions);
    })
  );

export const minifutureLifeEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_DATA_LOADED),
    map(({ payload }) => payload && payload.pricings),
    filter((data) => !!data),
    map((data) => {
      const formData = priceFormValueSelector(state$.value);
      return loadAllVariable(formData, data, LIMITATION_RELATION_SWAP);
    }),
    map((limitation) => {
      return setLimitationForm({
        limitation,
        limitationRelation: LIMITATION_RELATION_SWAP,
      });
    })
  );

const trailPricesFilter = (state$) => {
  return (
    state$.value &&
    state$.value.minifuture &&
    state$.value.minifuture.trailPrice &&
    Array.isArray(state$.value.minifuture.trailPrice.cards)
  );
};
const neonSubscriptionEpic = (action$, state$) =>
  merge(action$.pipe(ofType(MINIFUTURE_FORM_LOAD))).pipe(
    switchMap(() => {
      return from(
        subscribeSwapCommodityContracts(STRUCTURE_MINIFUTURE)
      ).pipe(
        filter(() => trailPricesFilter(state$)),
        bufferTime(BUFFER_TIMEOUT),
        filter((data) => !!data.length),
        distinctUntilChanged(distinctObjectChanges),
        map((commodity) => neonCommodityUpdateAction(commodity)),
        takeUntil(
          action$.pipe(
            filter((action) => {
              return priceFormDestroyFilter(action);
            })
          )
        )
      );
    })
  );

export const cardLiveUpdate = (action$, state$) =>
  action$.pipe(
    ofType(NEON_COMMODITY_UPDATE),
    filter(() => priceFormValueSelector(state$.value)),
    filter(
      ({ payload }) =>
        !isEmpty(
          state$.value.minifuture.trailPrice &&
          state$.value.minifuture.trailPrice.cards
        ) && !isEmpty(payload)
    ),
    map(({ payload }) => payload),
    map((commodity) => {
      const cards = updateCardsState(
        state$.value.minifuture.trailPrice.cards,
        commodity
      );
      return minifutureCardsUpdate({
        ...state$.value.minifuture.trailPrice,
        cards,
      });
    })
  );

export const clearProductForStructureForm = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("type")),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("commodity")),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    )
  ).pipe(
    debounceTime(100),
    distinctUntilChanged(),
    switchMap(() => from([
      change(PRICING_FORM, 'product', null),
      loadedMinifutureData({ products: [] })
    ]))
  );


export const loadMinifutureProducts = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("type")),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("commodity")),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(
      filter(changeAnyFormFieldActionFilter("clientId")),
      filter(() => trailPricesFilter(state$)),
      filter(({ payload }) => !!payload)
    ),
    action$.pipe(ofType(MINIFUTURE_CARD_REFETCH))
  ).pipe(
    debounceTime(100),
    distinctUntilChanged(),
    map(() => priceFormValueSelector(state$.value)),
    filter(
      ({ commodity }) =>
        !isEmpty(state$.value.minifuture.pricings) && !!commodity
    ),
    switchMap(({ commodity, type, clientId }) => {
      let requestPayload = {
        commodityCode: extractSwapContract({
          pricings: state$.value.minifuture.pricings,
          commodity,
        })?.commodityCode,
        type,
        clientId
      };

      return from(loadedMinifutureProducts(requestPayload)).pipe(
        takeUntil(
          action$.pipe(
            filter(
              (action) =>
                action.type === AUTH_LOGOUT || priceFormDestroyFilter(action)
            )
          )
        )
      );
    }),
    switchMap((data) => {
      const actions = [loadedMinifutureData({ products: data })];
      return from(actions);
    })
  );

export default combineEpics(
  clearProductForStructureForm,
  loadMinifutereForm,
  minifutureLifeEpic,
  neonSubscriptionEpic,
  cardLiveUpdate,
  loadMinifutureProducts,
);
