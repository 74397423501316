import { REFERENCE_TYPE_LIVE } from 'pages/price/mini/components/pricinigMiniForm/constants';
import { PRICING_MINI_FORM } from 'pages/price/mini/components/pricinigMiniForm/PricingMiniForm';
import { pricingMiniFormValuesSelector, validateInputNumber } from 'pages/price/mini/components/pricinigMiniForm/utils';
import { blur, change, touch, untouch } from 'redux-form';
import { combineEpics, ofType } from 'redux-observable';
import { miniAccumulatorCardGetReferencePriceFinish, miniAccumulatorCardUpdate, PRICING_MINI_ACCUMULATOR_CARD_FIELD_VALUES, PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE, setMiniAccumulatorFieldValues } from 'redux/actions/accumulator-mini';
import { distinctObjectChanges } from 'redux/epics/utils';
import { getReferencePrice, getScheduleDate } from 'redux/queries/pricing-mini';
import { from, merge, concat } from 'rxjs';
import { concatMap, debounceTime, delay, distinctUntilChanged, filter, map, switchMap, mergeMap } from 'rxjs/operators';
import { changeDynamicFormNameActionFilter } from 'utils/reduxFormSelector';
import { getCardId } from '../structures/vanilla/utils';
import { findPricinigMiniCard } from './limitations';
import { emptyPricingFormFilter, getPricingMiniFormName, pricinigMiniFormFilter, updatePricingMiniCard } from './utils';
import { PREMIUM_AMOUNT_FIELD } from 'redux/epics/constants';
import { TAS_XML_TYPE } from '@agile/common-types/build/pricing-mini/types'


const getReferencePriceLoaderEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE),
    filter(() => emptyPricingFormFilter(state$) && state$.value.accumulatorMini.cards?.length > 0),
    concatMap(({ payload: { id } }) => {
      return from([
        miniAccumulatorCardUpdate({
          id,
          isCalculating: true,
        })
      ])
    }),
  );

const getReferencePriceEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARD_GET_REFERENCE_PRICE),
    filter(() => emptyPricingFormFilter(state$) && state$.value.accumulatorMini.cards?.length > 0),
    map(({ payload: { id: cardId, reset = true, updateFormFieldsValues = false, ...rest } = {} }) => {
      const formName = getPricingMiniFormName(cardId);
      const formData = pricingMiniFormValuesSelector(formName)(state$.value);
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      return { formName, formData, cardId, card, reset, updateFormFieldsValues, ...rest };
    }),
    filter(({ formData, card = {} }) => formData && formData.contractExpiry && card.selectedUnderlying),
    concatMap(({ formName, card, reset, updateFormFieldsValues, notifyWhenFinish, changeReferenceType = false }) => {
      return from(getReferencePrice(card))
        .pipe(
          filter(({ referencePrice }) => !(!referencePrice && !reset)),
          switchMap(({ referencePrice }) => {
            const actions = [
              change(formName, 'reference', referencePrice),
              untouch(formName, 'reference'),
              ...updateReferencePriceActions(state$, formName, referencePrice, updateFormFieldsValues, !referencePrice && notifyWhenFinish),
            ];

            if (referencePrice && notifyWhenFinish) actions.push(miniAccumulatorCardGetReferencePriceFinish());
            if (changeReferenceType) actions.push(change(formName, 'referenceType', REFERENCE_TYPE_LIVE));

            return from(actions);
          }),
        )
    }),
  );

export const updateReferencePriceActions = (state$, formName, reference, updateFormFieldsValues = false, isReferencePriceForSolve = false) => {
  const cardId = getCardId({ form: formName });
  const formData = pricingMiniFormValuesSelector(formName)(state$.value);
  const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
  const { roundingRule = 0 } = card;
  const { newCard, actions: fieldActions = [], fieldsDisabled = [] } = updatePricingMiniCard(formName, formData, card, reference, true, roundingRule);
  const fields = Array.isArray(card.fields) ? card.fields : [];
  if (reference) {
    newCard.isCalculating = false;
  }
  const actions = [
    miniAccumulatorCardUpdate({
      ...newCard,
      trailId: null,
      tradeId: null,
      isTrade: false,
      errorText: null,
      isReferencePriceForSolve,
    }),
    ...fields
      .map(({ name }) => untouch(formName, name)),
  ];

  if (updateFormFieldsValues) {
    const updateFieldsActions = fieldActions
      .filter(({ meta }) => !fieldsDisabled.includes(meta.field));
    actions.push(setMiniAccumulatorFieldValues({ actions: updateFieldsActions }));
  }

  return actions;
};

export const updateReferencePriceMiniEpic = (action$, state$) =>
  action$.pipe(
    filter(changeDynamicFormNameActionFilter(PRICING_MINI_FORM, 'reference')),
    filter(pricinigMiniFormFilter),
    filter(({ payload }) => !!payload && !validateInputNumber('reference', parseFloat(payload)) && state$.value.accumulatorMini.cards),
    map(({ meta, payload: reference }) => ({ form: meta.form, reference, meta })),
    distinctUntilChanged(distinctObjectChanges),
    debounceTime(100),
    switchMap(({ form: formName }) => {
      const formData = pricingMiniFormValuesSelector(formName)(state$.value);
      const reference = formData?.reference;
      const actions = updateReferencePriceActions(state$, formName, reference);
      return from(actions);
    })
  );

// Epic trigger field validation and apply min, max ranges
// Redux Form call validate only if user change input value
export const updateReferenceValidateTriggerPriceMiniEpic = (action$, state$) =>
  action$.pipe(
    filter(changeDynamicFormNameActionFilter(PRICING_MINI_FORM, 'reference')),
    filter(pricinigMiniFormFilter),
    filter(({ payload }) => !!payload && !validateInputNumber('reference', parseFloat(payload)) && state$.value.accumulatorMini.cards?.length),
    map(({ meta, payload: reference }) => ({ form: meta.form, reference, meta })),
    distinctUntilChanged(distinctObjectChanges),
    debounceTime(150),
    switchMap(({ form: formName }) => {
      const formData = pricingMiniFormValuesSelector(formName)(state$.value) || {};
      const cardId = getCardId({ form: formName });
      const card = findPricinigMiniCard(state$.value.accumulatorMini.cards, cardId) || {};
      const { useMsa, xmlType } = card;
      const fields = Array.isArray(card.fields) ? card.fields : [];
      let actions = [];
      fields
        .forEach(field => {
          const { name, disabled, isLock } = field;
          actions.push(blur(formName, name, null));
          const isEnablePremiumForTasStructure = name === PREMIUM_AMOUNT_FIELD && xmlType.toLowerCase() === TAS_XML_TYPE && useMsa;
          if (!(disabled && isLock) || isEnablePremiumForTasStructure) {
            actions.push(blur(formName, name, formData[name]));
          }
          actions.push(touch(formName, name));

        });
      return from(actions);
    })
  );

export const setFieldValuesPriceMiniEpic = action$ =>
  action$.pipe(
    ofType(PRICING_MINI_ACCUMULATOR_CARD_FIELD_VALUES),
    delay(50), //Wait until card render and applies min, max ranges for input type number
    filter(({ payload }) => Array.isArray(payload?.actions)),
    switchMap(({ payload: { actions = [] } }) => from(actions)),
  );

const fixingsLoading$ = (id, isFixings = false) => from([
  miniAccumulatorCardUpdate({
    id,
    isFixings,
  })
])

const getFixings$ = (variables, form, id) => {
  const { endDate } = variables;
  if (!endDate) return from([]);
  return from(getScheduleDate(variables)).pipe(
    switchMap((data) => {
      if (!data) return from([]);
      const { size = null, frequency = null, calendar = [], dailySize = null } = data;
      return from([
        change(form, 'scheduleDates', `${size} ${frequency}`),
        change(form, 'msaFixings', size),
        change(form, 'calendar', calendar),
        miniAccumulatorCardUpdate({
          id,
          dailySize,
        })
      ])
    })
  )
}


export const updateScheduleDate = (action$, state$) =>
  merge(
    action$.pipe(filter(changeDynamicFormNameActionFilter(PRICING_MINI_FORM, 'structureExpiryDate'))),
    action$.pipe(filter(changeDynamicFormNameActionFilter(PRICING_MINI_FORM, 'startDate'))),
    action$.pipe(filter(changeDynamicFormNameActionFilter(PRICING_MINI_FORM, 'endDate'))),
  ).pipe(
    // debounceTime(150),
    map(({ meta: { form } }) => {
      const {
        startDate,
        endDate,
        structureExpiryDate,
      } = pricingMiniFormValuesSelector(form)(state$.value) || {};

      const { pricingCardId: cardId, id, underlyingId, selectedUnderlying } = findPricinigMiniCard(state$.value.accumulatorMini.cards, getCardId({ form })) || {};

      const variables = {
        cardId,
        underlyingId,
        underlyingDataId: selectedUnderlying?.id,
        startDate: startDate || null,
        endDate: endDate || structureExpiryDate,
      }
      return { id, variables, form }
    }),
    mergeMap(({ id, variables, form }) => concat(
      fixingsLoading$(id, false),
      getFixings$(variables, form, id),
      fixingsLoading$(id, true),
    )),
  );

export default combineEpics(
  getReferencePriceLoaderEpic,
  getReferencePriceEpic,
  updateReferencePriceMiniEpic,
  updateReferenceValidateTriggerPriceMiniEpic,
  setFieldValuesPriceMiniEpic,
  updateScheduleDate,
);
