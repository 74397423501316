import { STRUCTURE_AUTOCALLABLE } from "constants.js";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import {
  PRICING_STRUCTURED_FORM,
  PRICING_STRUCTURED_FORM_TAG,
  PRICING_STRUCTURED_FORM_SHEDULE,
} from "pages/structured-products/components/forms/constants";
import { combineEpics, ofType } from "redux-observable";
import { AUTH_LOGOUT } from "redux/actions/auth";
import {
  setScheduleValidity,
  restoreStructuredHistoryAction,
  loadStructuredProductsFormDataLoaded,
  setStructureProductsFormStructures,
  setStructuredProductsLoader,
  structuredProductsStructureSet,
  STRUCTURED_PRODUCTS_FORM_LOAD,
  STRUCTURED_PRODUCTS_FORM_RESTORE_START,
  STRUCTURED_PRODUCTS_FORM_RESTORE_TRIGGER,
  bankHolidaysSet,
  STRUCTURED_PRODUCTS_SCHEDULE_SET,
} from "redux/actions/structured-products";
import { notificationErrorSimple } from "redux/alerts/actions";
import {
  getStructuredProducts,
  getStructuredProductsFormData,
} from "redux/queries/structured-products/";
import { from, merge, EMPTY } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { changeActionFilter } from "utils/reduxFormSelector";
import { priceFormDestroyFilter } from "../price/filters";
import { getStructuredProductByTag } from "pages/structured-products/components/forms/tag/query.js";
import { getSingleGraphQlError } from "redux/epics/utils";
import { focus, stopSubmit, touch } from "redux-form";
import { TAG_ACTION_TAG } from "redux/actions/structured-products.js";
import {
  getBankHolidays,
  BANK_HOLIDAYS_TYPE,
} from "components/form/BankHolidaysCalendar/query";
import { SCHEDULE_OPTIONS_FIELDS } from "pages/structured-products/components/output/schedule/utils";

export const loadStructuredProductForm = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_LOAD),
    switchMap(() => {
      return from(getStructuredProductsFormData(STRUCTURE_AUTOCALLABLE)).pipe(
        catchError(() =>
          from([notificationErrorSimple(`Can't fetch autocallable options`)]),
        ),
        takeUntil(
          action$.pipe(
            filter(
              (action) =>
                action.type === AUTH_LOGOUT || priceFormDestroyFilter(action),
            ),
          ),
        ),
      );
    }),
    switchMap((data) => {
      const actions = [
        structuredProductsStructureSet(STRUCTURE_AUTOCALLABLE),
        loadStructuredProductsFormDataLoaded({ data, isFirstRequest: true }),
      ];
      return from(actions);
    }),
  );

export const loaderStructuredProductForm = (action$) =>
  merge(
    action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_RESTORE_TRIGGER)),
    action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_LOAD)),
    action$.pipe(ofType(STRUCTURED_PRODUCTS_FORM_RESTORE_START)),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, "structure")),
      filter(({ payload }) => payload),
      map(({ payload }) => payload),
      distinctUntilChanged(),
    ),
  ).pipe(mapTo(setStructuredProductsLoader(true)));

export const initStructures = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_LOAD),
    switchMap(() => {
      return from(getStructuredProducts()).pipe(
        filter((getStructuredProducts) => isArray(getStructuredProducts)),
        switchMap((getStructuredProducts) => {
          if (isEmpty(getStructuredProducts) || !getStructuredProducts) {
            return from([
              notificationErrorSimple("Structures isn't available"),
            ]);
          }
          const actions = [
            setStructureProductsFormStructures(sortBy(getStructuredProducts)),
          ];
          return from(actions);
        }),
        catchError(() =>
          from([notificationErrorSimple("Structures isn't available")]),
        ),
      );
    }),
  );

export const loadBankHolidays = (action$, state$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_SCHEDULE_SET),
    debounceTime(100),
    switchMap(() => {
      const underlyingIds = state$.value?.structuredProducts?.underlyings
        .map(({ value }) => value)
        .filter((value) => value);
      if (!underlyingIds.length) return EMPTY;
      return from(
        getBankHolidays(underlyingIds, BANK_HOLIDAYS_TYPE.EQ_UNDERLYING),
      ).pipe(
        filter((getBankHolidays) => isArray(getBankHolidays)),
        switchMap((getBankHolidays) => {
          return from([
            setScheduleValidity(false),
            bankHolidaysSet(getBankHolidays),
            touch(
              PRICING_STRUCTURED_FORM_SHEDULE,
              SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE,
            ),
            focus(
              PRICING_STRUCTURED_FORM_SHEDULE,
              SCHEDULE_OPTIONS_FIELDS.STRIKE_DATE,
            ),
          ]);
        }),
        catchError(() =>
          from([notificationErrorSimple("Bank holidays isn't available")]),
        ),
      );
    }),
  );

const triggerRestoreTag = (action$) =>
  action$.pipe(
    ofType(STRUCTURED_PRODUCTS_FORM_RESTORE_TRIGGER),
    filter(({ payload }) => payload),
    debounceTime(550),
    switchMap(({ payload: { tag, action } }) =>
      from(getStructuredProductByTag(tag, action)).pipe(
        switchMap((data) => from([restoreStructuredHistoryAction(data)])),
        catchError((error) => {
          const err = getSingleGraphQlError(error);
          const msg = err?.message ? err.message : null;
          const errMsgDescription = msg?.description ? msg.description : null;
          const errMsgTitle = msg?.title ? msg.title : null;

          const actions = [setStructuredProductsLoader(false)];

          if (action === TAG_ACTION_TAG) {
            actions.push(
              stopSubmit(PRICING_STRUCTURED_FORM_TAG, {
                _error: [{ code: "E54" }],
              }),
            );
          } else {
            actions.push(
              notificationErrorSimple(
                errMsgDescription ||
                  errMsgTitle ||
                  `Can't fetch autocallable schedule`,
              ),
            );
          }

          return from(actions);
        }),
      ),
    ),
  );

export default combineEpics(
  initStructures,
  loadStructuredProductForm,
  loaderStructuredProductForm,
  triggerRestoreTag,
  loadBankHolidays,
);
